import './app.css';

function App() {
    return (
        <div className="app">
            <header className="header">
            </header>
        </div>
    );
}

export default App;
